import axios from 'axios';
// eslint-disable-next-line max-len
import { CustomMeasureAndInstallExpConfiguration as expConfig } from '../utility/customMeasureAndInstallExpConfiguration';

const AllDictionaryEntriesPath = '/DictionaryManagement/Definition/AllDictionaryEntries'.toUpperCase();
const DictionaryEntryForTerm = '/DictionaryManagement/Definition/DictionaryEntryForTerm'.toUpperCase();
const LogoutAndRemoveCookie = '/InternalUser/LogoutAndRemoveCookie'.toUpperCase();

export const initializeIntercepts = () => {
  axios.interceptors.request.use(function (config) {
    const { url } = config;
    if (!url) return config;

    const urlToUpper = url.toUpperCase();
    if (urlToUpper.startsWith(AllDictionaryEntriesPath)
      || urlToUpper.startsWith(DictionaryEntryForTerm)
      || urlToUpper.startsWith(LogoutAndRemoveCookie)) {
      // eslint-disable-next-line no-param-reassign
      config.url = `${expConfig.AUTOBAHN_BASE_URL}${url}`;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });
};
