export const CustomMeasureAndInstallExpConfiguration = {
  AUTOBAHN_BASE_URL: typeof window === 'undefined'
    ? 'base url is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['autobahn.baseUrl'],

  DESIGN_BUILDER_BASE_URL: typeof window === 'undefined'
    ? 'base url is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['design.builder.baseUrl'],

  DESIGN_BUILDER_API_KEY: typeof window === 'undefined'
    ? 'design.builder.api.key is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['design.builder.api.key'],

  INSTALL_HD_BASE_URL: typeof window === 'undefined'
    ? 'base url is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['install.hd.baseUrl'],

  MANDI_BASE_URL: typeof window === 'undefined'
    ? 'base url is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['mandi.baseUrl'],

  SITE_ID: typeof window === 'undefined'
    ? 'siteId is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['site-id'],

  COOKIE_DOMAIN: typeof window === 'undefined'
    ? 'cookieDomain is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['cookie.domain'],

  THD_CONTACT_Number: typeof window === 'undefined'
    ? 'THD contact number is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['thd-contact-number'],

  LOG_OMNITURE_EVENTS_TO_COSOLE: typeof window === 'undefined'
    ? 'LogOmnitureEventsToConsole is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['log-omniture-events-to-console'],

  LiveClickerApiKey: typeof window === 'undefined'
    ? 'LiveClickerApiKey is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['live.clicker.api.key'],

  LiveClickerClientId: typeof window === 'undefined'
    ? 'LiveClickerClientId is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['live.clicker.client.id'],

  LiveClickerComponentId: typeof window === 'undefined'
    ? 'LiveClickerComponentId is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['live.clicker.component.id'],

  LiveClickerPlayerIdProduct: typeof window === 'undefined'
    ? 'LiveClickerPlayerIdProduct is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['live.clicker.player.id.product'],

  LiveClickerPlayerIdConfigurator: typeof window === 'undefined'
    ? 'LiveClickerPlayerIdConfigurator is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['live.clicker.player.id.configurator'],

  LiveClickerPlayerIdContent: typeof window === 'undefined'
    ? 'LiveClickerPlayerIdContent is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['live.clicker.player.id.content'],

  MicroAppBundleBaseUrl: typeof window === 'undefined'
    ? 'MicroAppBundleBaseUrl is not available in server side'
    : window?.__EXPERIENCE_PROPS__?.expConfiguration?.['micro.app.bundle.base.url'],
};

export const appendAutobahnUrl = (path) => {
  return `${CustomMeasureAndInstallExpConfiguration.AUTOBAHN_BASE_URL}${path}`;
};
