/* eslint-disable no-console */
/* eslint-disable no-undef */
import { getCookie, removeEmpty, removeSpecialChars } from '../windowHelper';
import { CustomMeasureAndInstallExpConfiguration } from '../customMeasureAndInstallExpConfiguration';
import {
  setSite,
  setPage,
  setProduct,
  setUserProfile,
  setProductInfo,
  setPageError,
  setUserLocalization,
} from './setDigitalObjectFunctions';

export const setAuthenticationStatus = () => {
  if (getCookie('internalUser')) {
    const authenticationStatus = 'rep';
    setUserProfile({ authenticationStatus });
  }
};

export const setGpsTimeout = () => {
  if (!THD_LOCALIZER_AUTO_INIT.Localizer.status.getGpsStatus()) {
    const gpsPermission = 'gps timeout';
    setUserLocalization({ gpsPermission });
  }
};

export const setDepartmentInfo = () => {
  const departmentInfo = {
    department: '59',
    class: '27',
    subclass: '65',
  };
  setProductInfo({ ...departmentInfo });
};

export const setProductAvailabilityStatus = () => {
  const availabilityStatus = {
    backOrder: 'false',
    shipping: {
      sth: 'available',
      skuStatus: 'active',
    },
    store: {
      skuStatus: 'n/a',
    },
    type: 'online',
  };
  setProductInfo({ availabilityStatus });
};

export const setProductETA = (startDate, endDate) => {
  const eta = {
    sthStartDate: startDate.split('T')[0] || 'n/a',
    sthEndDate: endDate.split('T')[0] || 'n/a',
  };
  setProductInfo({ eta });
};

export const setSiteInfo = () => {
  const siteInfo = {
    businessType: THDCustomer.default.customerType?.toLowerCase() || 'n/a',
  };
  setSite(siteInfo);
};

export const setOnLoadPageError = (type, pageSection, message, code) => {
  const errObj = {
    type,
    displayType: 'page',
    pageSection,
    message: removeSpecialChars(message),
    code,
  };
  setPageError(errObj);
};

export const ddoEventInit = (eventObj) => {
  try {
    if (window._T && typeof _T.sendEventDataToAnalytics !== 'undefined') {
      _T.sendEventDataToAnalytics(eventObj);

      if (CustomMeasureAndInstallExpConfiguration.LOG_OMNITURE_EVENTS_TO_COSOLE) {
        console.log('Sent Omniture Event: ' + JSON.stringify(eventObj));
      }
    } else {
      window._T = window._T ? _T : {};
      _T.eventData = window._T.eventData ? _T.eventData : [];
      _T.eventData.push(eventObj);
      if (console && console.log) {
        console.log('window._T undefined. Attempting to publish:' + JSON.stringify(eventObj));
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const ddoInit = () => {
  _T = window._T ? _T : {};
  if (typeof _T.pageData === 'undefined') {
    _T.pageData = [];
  }
  _T.pageData.push(digitalData);
};

export const analyticsInitPcp = () => {
  if (digitalData) {
    let dD = digitalData?.init();
  }
  setAuthenticationStatus();
  setGpsTimeout();
  setSiteInfo();
};

export const analyticsInit = () => {
  if (digitalData) {
    let dD = digitalData?.init();
  }
  setAuthenticationStatus();
  setDepartmentInfo();
  setGpsTimeout();
  setProductAvailabilityStatus();
  setSiteInfo();
};

export const ensightenInit = () => {
  window.digitalData = window.digitalData || {};
  const data = removeEmpty(window.ensightenData);

  if (data) {
    const { site, product } = data;
    const productObj = product[0] || {};
    if (site) {
      setSite(site);
    }
    if (productObj) {
      setProduct(productObj);
      const {
        productInfo: { productName: prodName },
      } = productObj;
      if (prodName) {
        const name = removeSpecialChars(prodName)?.toLowerCase();
        setProductInfo({ name });
      }
      if (window?.digitalData?.product[0]?.productInfo?.productName) {
        delete window.digitalData.product[0].productInfo.productName;
      }
    }
  }
};
