// Page
export const setPage = (page) => {
  window.digitalData.page = window.digitalData.page || {};
  window.digitalData.page = { ...window.digitalData.page, ...page };
};
export const setPageInfo = (pageInfo) => {
  window.digitalData.page.pageInfo = window.digitalData.page.pageInfo || {};
  window.digitalData.page.pageInfo = { ...window.digitalData.page.pageInfo, ...pageInfo };
};
export const setPageCategory = (pageCategory) => {
  window.digitalData.page.category = window.digitalData.page.category || {};
  window.digitalData.page.category = { ...window.digitalData.page.category, ...pageCategory };
};
export const setPageError = (pageError) => {
  window.digitalData.page.error = window.digitalData.page.error || [];
  window.digitalData.page.error.push({ ...pageError });
};

// Product
export const setProduct = (product) => {
  window.digitalData.product[0] = window.digitalData.product[0] || {};
  window.digitalData.product[0] = {
    ...window.digitalData.product[0],
    ...product,
    productInfo: {
      ...window.digitalData.product[0]?.productInfo,
      ...product?.productInfo,
    },
  };
};
export const setProductInfo = (productInfo) => {
  window.digitalData.product[0] = window.digitalData.product[0] || {};
  window.digitalData.product[0].productInfo = window.digitalData.product[0]?.productInfo || {};
  window.digitalData.product[0].productInfo = {
    ...window.digitalData.product[0].productInfo,
    ...productInfo,
  };
};

// Site
export const setSite = (site) => {
  window.digitalData.site = window.digitalData.site || {};
  window.digitalData.site = { ...window.digitalData.site, ...site };
};

// User
export const setUserProfile = (userProfile) => {
  window.digitalData.user.profile = window.digitalData.user.profile || {};
  window.digitalData.user.profile = { ...window.digitalData.user.profile, ...userProfile };
};

export const setUserLocalization = (userLocalization) => {
  window.digitalData.user.localization = window.digitalData.user.localization || {};
  window.digitalData.user.localization = { ...window.digitalData.user.localization, ...userLocalization };
};