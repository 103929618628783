import { map } from 'lodash';
import { CustomMeasureAndInstallExpConfiguration } from './customMeasureAndInstallExpConfiguration';

const setWindowPageModel = (configuratorModel) => {
  window.pageContextJavascript = {
    pageModel: configuratorModel
  };
};

const setEnsightenData = (data) => {
  window.ensightenData = data;
};

const setWindowGlobalConfig = () => {
  window.globalConfig = {
    siteId: CustomMeasureAndInstallExpConfiguration.SITE_ID,
    contactNumber: CustomMeasureAndInstallExpConfiguration.THD_CONTACT_Number,
    liveClicker: {
      apiKey: CustomMeasureAndInstallExpConfiguration.LiveClickerApiKey,
      clientId: CustomMeasureAndInstallExpConfiguration.LiveClickerClientId,
      componentId: CustomMeasureAndInstallExpConfiguration.LiveClickerComponentId,
      playerIdProduct: CustomMeasureAndInstallExpConfiguration.LiveClickerPlayerIdProduct,
      playerIdConfigurator: CustomMeasureAndInstallExpConfiguration.LiveClickerPlayerIdConfigurator,
      playerIdContent: CustomMeasureAndInstallExpConfiguration.LiveClickerPlayerIdContent
    },
  };
};

const removeEmpty = (obj) => {
  return Object.entries(obj)
    .filter(([_, v]) => v != null && v !== '')
    .reduce(
      (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmpty(v) : v }),
      {}
    );
};

const setWindowDigitalData = (data) => {
  const o = removeEmpty(data);
  const productName = o?.product[0]?.productInfo?.productName || null;
  if (productName) {
    o.product[0].productInfo.productName = productName.replace(/[^a-zA-Z0-9 ]/g, '');
  }
  if (window.digitalData == null) {
    window.digitalData = o;
  } else {
    window.digitalData.product = [o.product[0]];
    window.digitalData.page = o.page;
  }
};

const setWindowBreadCrumbs = (breadCrumbs) => {
  window.breadCrumbs = map(breadCrumbs, (breadCrumb) => {
    if (breadCrumb.url) {
      return { href: breadCrumb.url, name: breadCrumb.text };
    }
    return { name: breadCrumb.text };
  }
  );
};

const setWindowData = (configuratorModel, digitalData, breadCrumbs) => {
  setWindowPageModel(configuratorModel);
  setWindowGlobalConfig();
  setWindowDigitalData(digitalData);
  setWindowBreadCrumbs(breadCrumbs);
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
};

const getCookie = (name) => {
  let value = '; ' + document.cookie;
  let parts = value.split('; ' + name + '=');
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return null;
};

const removeSpecialChars = (inputStr) => {
  if (inputStr) {
    const outputStr = inputStr.replace(/[^a-zA-Z0-9 ]/g, '');
    return outputStr;
  }
  return inputStr;
};

export {
  setEnsightenData,
  setWindowPageModel,
  setWindowGlobalConfig,
  setWindowDigitalData,
  setWindowData,
  getDeviceType,
  getCookie,
  removeEmpty,
  removeSpecialChars
};
