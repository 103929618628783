import React, { useContext } from 'react';
import { Route, ScrollToTop, Routes } from '@thd-olt-component-react/router';
import { AppContext } from '@thd-nucleus/app-render';
import { Error } from './pages';
import { getRoutes } from './routes';
import './styles/app.scss';
import { initializeIntercepts } from './api';

initializeIntercepts();

export const App = () => {
  const { instance } = useContext(AppContext);

  const {
    currentRouteName,
    currentRouteComponent
  } = instance.router;
  const routes = getRoutes(currentRouteName, currentRouteComponent);
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path={routes.pipMandi.path} element={<routes.pipMandi.component />} errorElement={<Error />} />
        <Route exact path={routes.pip.path} element={<routes.pip.component />} errorElement={<Error />} />
        <Route
          exact
          path={routes.pipEstimate.path}
          element={<routes.pipEstimate.component />}
          errorElement={<Error />}
        />
        {routes.pcp.path.map((route) => {
          return <Route exact path={route} element={<routes.pcp.component />} errorElement={<Error />} />;
        })}
        <Route path="/error" element={<Error />} errorElement={<Error />} />
        <Route exact path={routes.home.path} element={<routes.home.component />} errorElement={<Error />} />
        <Route path={routes.experience.path} element={<routes.experience.component />} errorElement={<Error />} />
      </Routes>
    </>
  );
};

App.propTypes = {};

App.defaultProps = {};
