import React from 'react';
import { ClientContext } from '@thd-nucleus/app-render/dist/client';
import B2BTheme from '@thd-olt-global/b2b-theme';
import { NewRelicBridge } from '@thd-olt-component-react/new-relic-bridge';
import { ThdAnalytics } from '@thd-olt-component-react/thd-analytics';
import { whyDidYouRender } from '../wdyr';
import { App } from './App';
import { getRoutes } from './routes';
import { ddoInit, ddoEventInit } from './utility/analytics';

ThdAnalytics.init();
NewRelicBridge.init();
whyDidYouRender.init();

document.addEventListener('DOMContentLoaded', async () => {
  const client = new ClientContext({
    opts: {
      federation: true
    }
  });
  client.setExperienceRoutes(getRoutes());
  if (client.customer.type === 'b2b') {
    client.setTheme({ name: 'b2b', template: B2BTheme });
  }

  window.ddoInit = ddoInit;
  window.ddoEventInit = ddoEventInit;

  client.render(<App
    prefetchPip={client.featureSwitches.prefetchPip}
    productPodHoverDelay={client.featureSwitches.productPodHoverDelay}
  />);
});
